<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <b-col
        cols="12"
        xl="9"
        md="9"
      >
        <g-form @submit="save">
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <b-card-body
              class="invoice-padding pt-0"
              style="margin-bottom: -30px;"
            >
              <b-row
                v-if="id > 0 && feesInvoices.length > 0"
                class="center-element flex-column pt-2 pb-2"
              >
                <span
                  style="background-color: #4b8256; padding: 10px; color: #fff; border-radius: 5px; border-radius: 5px; letter-spacing: 1.5px; display: block;"
                >
                  {{ this.$t('invoicesCreatedUsingCurrentFee') }}
                </span>
                <div
                  class="mt-1"
                >
                  <a
                    style="padding: 2px 15px; margin: 10px; border-radius: 5px; background-color: #4b8256; color: #fff;"
                    v-for="invoice in feesInvoices"
                    :key="invoice"
                    @click="Help(invoice.salesId)"
                  >
                    {{ invoice.salesCode }}
                  </a>
                </div>
              </b-row>

              <!-- student and trasnaction date & time section  -->
              <b-row class="invoice-spacing">

                <!-- code -->
                <b-col
                  v-if="selectedItem.id > 0"
                  md="2"
                >
                  <g-field
                    :value="selectedItem.code"
                    label-text="transactionNumber"
                    disabled
                  />
                </b-col>

                <!-- transaction date  -->
                <b-col
                  v-if="!currentBranch.setDefaultDate"
                  :md="selectedItem.id > 0 ? '3' : '4'"
                >
                  <g-field
                    :value="getDate(selectedItem.transactionDate)"
                    label-text="date"
                    disabled
                    name="date"
                  />
                </b-col>
                <b-col
                  v-else
                  :md="selectedItem.id > 0 ? '3' : '4'"
                >
                  <g-picker
                    :value.sync="selectedItem.transactionDate"
                    label-text="date"
                    name="date"
                  />
                </b-col>

                <!-- transaction time  -->
                <b-col
                  :md="selectedItem.id > 0 ? '3' : '4'"
                >
                  <b-form-group>
                    <g-field
                      label-text="transactionTime"
                      name="transactionTime"
                      readonly
                      :value.sync="selectedItem.transactionTime"
                    />
                  </b-form-group>
                </b-col>

                <!-- student -->
                <b-col
                  cols="12"
                  xl="4"
                  class="mb-lg-1"
                >
                  <label
                    style="font-size: 14px; margin-bottom: 7px"
                    for="student"
                  >
                    {{ $t('student') }}
                    <feather-icon
                      v-b-tooltip.hover="$t('student-new')"
                      icon="PlusIcon"
                      class="clickable"
                      @click="
                        () => {
                          addExtrnalStudent();
                        }
                      "
                    />
                  </label>
                  <student-autocomplete
                    ref="autoComlete"
                    :hide-label="true"
                    :value.sync="selectedItem.studentId"
                    :disabled="selectedItem.id > 0"
                    url="students/getStudentsTaxLookup"
                    :display-item="{
                      id: selectedItem.studentId,
                      uid: selectedItem.studentId,
                      arabicName: selectedItem.studentNameAr,
                    }"
                    style="margin-top: 0px;"
                    @change:action="(val) => changeStudent(val)"
                  />
                  <span
                    v-if="!selectedItem.studentId"
                    style="font-size: 11px; letter-spacing: 1.2px; color: brown; display: block; margin-top: 8px;"
                  > {{ $t('student') + ' ' + $t('required') }} </span>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- services section -->
            <b-card-body class="invoice-padding form-item-section">
              <b-row>
                <b-col cols="12">
                  <div class="d-none d-lg-flex">
                    <!-- headers -->
                    <b-row class="flex-grow-1 px-2 mb-50">
                      <b-col
                        cols="12"
                        lg="1"
                        class="service-title"
                      >
                        {{ $t('#') }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="2"
                        class="service-title"
                      >
                        {{ $t('notes') }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="4"
                        class="service-title"
                      >
                        {{ $t('service') }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="2"
                        class="service-title"
                      >
                        {{ $t('Price') }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="1"
                        class="service-title"
                      >
                        {{ $t('discounts') }}
                      </b-col>
                      <b-col
                        cols="12"
                        lg="2"
                        class="service-title"
                      >
                        {{ $t('priceAfterDiscount') }}
                      </b-col>
                    </b-row>
                    <div class="form-item-action-col" />
                  </div>
                </b-col>
              </b-row>
              <div
                ref="form"
                class="repeater-form"
                :style="{ height: trHeight }"
              >
                <b-row
                  v-for="(item, index) in invoiceData.items"
                  :key="index"
                  ref="row"
                  class="pb-2"
                >
                  <!-- services -->
                  <b-col cols="12">
                    <div class="d-flex border rounded">
                      <b-row class="flex-grow-1 p-2">

                        <!-- counter -->
                        <b-col
                          cols="12"
                          lg="1"
                          class="p-50"
                        >
                          <p class="mb-1 service-counter">
                            {{ index + 1 }}
                          </p>
                        </b-col>

                        <!-- notes -->
                        <b-col
                          cols="12"
                          lg="2"
                          class="service-feature"
                        >
                          <g-field
                            :value.sync="item.notes"
                            :short-desc="true"
                            size="sm"
                            class="my-0"
                            maxlength="500"
                          />
                          <small
                            class="textarea-counter-value float-right"
                          ><span class="char-count">{{
                            item.notes ? item.notes.length : 0
                          }}</span>
                            / 500
                          </small>
                        </b-col>

                        <!-- service -->
                        <b-col
                          cols="12"
                          lg="4"
                          class="service-feature"
                        >
                          <g-field
                            field="select"
                            rules="required"
                            :clearable="false"
                            :label="isRight ? 'arabicName' : 'englishName'"
                            :value.sync="item.id"
                            :dir="isRight ? 'rtl' : 'ltr'"
                            :options="itemsData"
                            :selectable="(service) => !service.stopped"
                            :disabled="isConvertToSale"
                            :placeholder="$t('selectService')"
                            @change="
                              (val) => {
                                item.serviceId = val.id;
                                item.price = val.serviceFees;
                                item.net = val.serviceFees;
                                item.total = val.serviceFees;
                                item.lineSerial = index + 1;
                                computeTaxAndPriceBeforeTax(item);
                                item.discountValue = val.discountValue;
                                item.discountPercentage = val.discountPercentage;
                                selectedItem.invoiceLines = invoiceData.items;
                                item.fixedPrecentage = val.isFeesFixed;
                              }
                            "
                          />
                        </b-col>

                        <!-- price -->
                        <b-col
                          cols="12"
                          lg="2"
                          class="service-feature"
                        >
                          <g-field
                            :value.sync="item.price"
                            type="number"
                            :disabled="item.fixedPrecentage === true"
                            class="mb-1"
                            @input="
                              (v) => {
                                computeTaxAndPriceBeforeTax(item);
                                computeTotal({ item: item });
                              }
                            "
                            @change="
                              (v) => {
                                computeTaxAndPriceBeforeTax(item);
                                computeTotal({ item: item });
                                priceChanged(item);
                              }
                            "
                          />
                        </b-col>

                        <!-- discount -->
                        <b-col
                          cols="12"
                          lg="1"
                          class="service-feature"
                        >
                          <g-field
                            :value.sync="item.discountValue"
                            type="number"
                            class="mb-1"
                            @input="
                              (v) => {
                                computeTaxAndPriceBeforeTax(item);
                                computeTotal({
                                  item: item,
                                  discountValue: item.discountValue,
                                });
                                discountChanged(item);
                              }
                            "
                          />
                        </b-col>

                        <!-- price after discount -->
                        <b-col
                          cols="12"
                          lg="2"
                          class="service-feature"
                        >
                          <g-field
                            :value.sync="item.priceBeforeTax"
                            type="number"
                            disabled
                            class="mb-1"
                          />
                        </b-col>
                      </b-row>

                      <!-- remove icon -->
                      <div
                        class="d-flex align-items-center border-left py-50 px-25"
                      >
                        <feather-icon
                          size="16"
                          icon="TrashIcon"
                          class="cursor-pointer"
                          stroke="firebrick"
                          @click="removeItem(index, item)"
                        />
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </div>

              <!-- add new service button -->
              <b-button
                v-if="!isConvertToSale && !selectedItem.isConvertedToSale"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="outline-primary"
                @click="addNewItemInItemForm"
              >
                {{ $t('addService') }}
                <feather-icon
                  icon="CloudLightningIcon"
                  size="15"
                  class="ml-25"
                />
              </b-button>
            </b-card-body>
          </b-card>
        </g-form>
      </b-col>

      <!-- Totals Section -->
      <b-col
        md="3"
        lg="3"
        class="d-flex flex-column"
      >
        <b-card class="border sticky-content-top">
          <b-row class="m-auto">
            <!-- invoice total value -->
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span class="font-weight-bolder text-nowrap mb-1">
                {{ $t('total') }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <g-field
                :value="fraction(selectedItem.total)"
                placeholder="total"
                type="number"
                name="total"
                size="sm"
                disabled
              />
            </b-col>

            <!-- discount value -->
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span class="font-weight-bolder text-nowrap mb-1">
                {{ $t('discont') }}
              </span>
            </b-col>
            <b-col
              md="4"
              lg="4"
            >
              <g-field
                :value.sync="selectedItem.discountValue"
                :disabled="true"
                size="sm"
                type="number"
                :rules="`min_value:0|max_value:${selectedItem.total}`"
                placeholder="discountValue"
                name="discountValue"
                @input="
                  (v) => {
                    selectedItem.discountValue < 0 ||
                      selectedItem.discountValue > selectedItem.total
                      ? (v = 0)
                      : v;
                    selectedItem.discountValue = v;
                    selectedItem.discountPercentage = 0;
                    computeDiscount({ value: v });
                  }
                "
              />
            </b-col>

            <!-- discount percentage -->
            <b-col
              md="5"
              lg="5"
            >
              <g-field
                :value.sync="selectedItem.discountPercentage"
                :disabled="true"
                size="sm"
                placeholder="percentage"
                name="discountPercentage"
                type="number"
                rules="min_value:0|max_value:100"
                disable
                @input="
                  (v) => {
                    selectedItem.discountPercentage < 0 ||
                      selectedItem.discountPercentage > 100
                      ? (v = 0)
                      : v;
                    selectedItem.discountPercentage = v;
                    computeDiscount({ Percentage: v });
                  }
                "
              >
                <template #append>
                  <b-input-group-text> % </b-input-group-text>
                </template>
              </g-field>
            </b-col>

            <!-- net -->
            <b-col
              md="3"
              class="p-0 d-flex align-items-center"
            >
              <span class="font-weight-bolder mb-2">
                {{ $t('net') }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="9"
            >
              <g-field
                :value="fraction(selectedItem.net)"
                size="sm"
                placeholder="net"
                type="number"
                name="Net"
                readonly
              />
            </b-col>
          </b-row>
        </b-card>

        <b-card
          class="border sticky-content-top2"
        >
          <b-row>
            <!-- saveAndPrint button -->
            <b-button
              v-if="!isConvertToSale"
              v-permission="'addDeservedFees'"
              class="mx-1 btn-block"
              variant="outline-primary"
              data-action-type="saveAndPrint"
              :disabled="selectedItem.isConvertedToSale || selectedItem.invoiceLines.length <= 0
              "
              @click="save('saveAndPrint')"
            >
              <feather-icon
                icon="PrinterIcon"
                size="15"
                class="mr-25"
              />
              {{ $t('saveAndPrint') }}
            </b-button>

            <!-- save button -->
            <b-button
              v-if="!isConvertToSale"
              v-permission="'addDeservedFees'"
              data-action-type="save"
              class="mx-1 btn-block"
              variant="relief-primary"
              :disabled="selectedItem.isConvertedToSale || selectedItem.invoiceLines.length <= 0"
              @click="save"
            >
              <feather-icon
                icon="SaveIcon"
                size="15"
                class="mr-25"
              />
              {{ $t('save') }}
            </b-button>

            <!-- addNew button -->
            <b-button
              v-if="!isConvertToSale"
              class="mx-1 btn-block"
              data-action-type="new"
              variant="outline-primary"
              @click="clearData"
            >
              <feather-icon
                icon="PlusSquareIcon"
                size="15"
                class="mr-50"
              />
              {{ $t('addNew') }}
            </b-button>

            <!-- convert to sale button -->
            <b-button
              v-if="isConvertToSale"
              v-permission="'convertToSalesInvoice'"
              data-action-type="edit"
              class="mx-1 btn-block"
              variant="relief-primary"
              :disabled="selectedItem.invoiceLines.length <= 0"
              @click="convertToSaleInvoice()"
            >
              <feather-icon
                icon="ShoppingBagIcon"
                size="15"
                class="mr-25"
              />
              {{ $t('convertToSalesInvoice') }}
            </b-button>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
// import Logo from '@core/layouts/components/Logo.vue';
import invoiceMixin from '@/mixin/invoiceMixin';
import { heightTransition } from '@core/mixins/ui/transition';
import Ripple from 'vue-ripple-directive';
import saveAs from 'file-saver';
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BFormGroup,
  VBToggle,
} from 'bootstrap-vue';
import reportMixin from '@/mixin/reportMixin';
import StudentAutocomplete from '@/components/StudentAutoComplete.vue';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BFormGroup,
    StudentAutocomplete,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition, invoiceMixin, reportMixin],
  props: ['id', 'isConvertToSale'],

  data() {
    return {
      currentLogo: localStorage.COMAPNY_LOGO,
      currentUser: localStorage.USERNAME,
      hasService: false,
      isRequested: false,
      fullscreen: false,
      teleport: true,
      isCalculatingDiscount: false,
      template: '',
      file: '',
      qrVal: '',
      transactionType: 'sales',
      students: {},
      pendingItem: {},
      items: [],
      errors: [],
      pendingItems: [],
      itemsData: [],
      feesInvoices: [],
      selectedItem: {
        code: '',
        isConvertedToSales: false
      },
      invoiceData: {
        items: [
          {
            itemArabicName: '',
            itemEnglishName: '',
            serviceId: '',
            price: 0,
            total: 0,
            net: 0,
            fixedPrecentage: false,
            priceBeforeTax: 0,
            discountValue: 0,
            discountPercentage: 0,
            priceTrafficedValue: 0,
            discountTrafficedValue: 0
          },
        ],
      },
      originalFees: [],
      currentFee: {
        seriveId: 0,
        lineNo: 0,
        price: 0,
        discount: 0,
        isValid: true
      }
    };
  },

  computed: {
    total() {
      return this.invoiceData.items.reduce((sum, item) => {
        sum += item.net;
        return sum;
      }, 0);
    },
    tableColumnsInvoice() {
      return [
        {
          key: 'transactionDate',
          label: this.$t('transactionDate'),
          sortable: true,
        },
        {
          key: 'paymentType',
          label: this.$t('paymentType'),
          sortable: true,
          isLocale: true,
        },
        {
          key: 'netBeforeTaxes',
          label: this.$t('netBeforeTax'),
          sortable: true,
        },
        {
          key: 'net',
          label: this.$t('net'),
          sortable: true,
        },
        { key: 'actions' },
      ];
    },
  },

  watch: {
    fullscreen(newVal) {
      if (newVal === true) {
        document.getElementById('virticalmenu').classList.add('display');
        document.getElementById('navBar').classList.add('display');
      } else {
        document.getElementById('virticalmenu').classList.remove('display');
        document.getElementById('navBar').classList.remove('display');
      }
    },
    'selectedItem.invoiceLines'(newVal, oldVal) {
      if (oldVal && oldVal.length > 0) this.selectedItem.invoicePayments = [];
    },
    total(newval) {
      this.selectedItem.total = newval;
      this.computeTotals();
    },
    'selectedItem.total'(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (this.students.discount) {
          this.computeDiscount({
            Percentage: this.selectedItem.discountPercentage,
          });
        } else {
          this.computeDiscount({ value: this.selectedItem.discountValue });
        }
      }
    },
  },
  beforeMount() {
    this.fiscalYearStart = this.currentYear.startDate;
    this.fiscalYearEnd = this.currentYear.endDate;
  },
  mounted() {
    this.selectedItem.studentName = this.$route.params.name;
    this.selectedItem.transactionDate = this.getDate();
    this.selectedItem.transactionTime = this.getTime();
    this.hasClass();

    if (this.id > 0) {
      this.get({ url: `DeservedFees/${this.id}` })
        .then((data) => {
          this.selectedItem = data;
          data.invoiceLines.forEach((item) => {
            item.id = item.serviceId;
            if (this.isConvertToSale) {
              // remeber current fees values
              this.originalFees.push({
                lineNo: item.lineSerial,
                seriveId: item.id,
                price: item.price - item.priceTrafficedValue,
                discount: item.discountValue - item.discountTrafficedValue,
                isValid: true
              });
              // maintain data
              item.price -= item.priceTrafficedValue
              item.discountValue -= item.discountTrafficedValue
              item.discountPercentage = (item.discountValue / 100.0).toFixed(2);
              item.total = item.price;
              item.net = item.price - item.discountValue;
              item.netBeforeTaxes = item.net;
              item.priceBeforeTax = item.net;
            }
          });
          this.invoiceData.items = data.invoiceLines;
          data.invoiceLines.forEach(item => {
            if (item.price === 0) {
              this.invoiceData.items = this.invoiceData.items.filter((val) => val.serviceId !== item.serviceId)
              this.selectedItem.invoiceLines = this.invoiceData.items
            }
          });
          for (let index = 0; index < this.selectedItem.invoiceLines.length - 1; index++) {
            this.$nextTick(() => {
              this.trAddHeight(this.$refs.row[0].offsetHeight);
            });
          }
        })
    }

    this.getFeesInvoices();
    this.itemsSearchProvider();
    this.initTrHeight();
    this.computeTotals();
  },
  created() {
    window.addEventListener('resize', this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight);
  },

  methods: {
    validateYear(date) {
      if (this.getDate(this.fiscalYearStart) > this.getDate(date) || this.getDate(date) > this.getDate(this.fiscalYearEnd)) {
      this.doneAlert({
          type: 'error',
          text: this.$t('youdonothaveaccesstothisyear'),
        });
        return false;
      }
      return true;
    },
    openModal() {
      this.$refs['upload-modal'].show();
    },
    removeAttachment() {
      this.selectedItem.attachmentFileExtension = '';
      this.selectedItem.attachmentBase64Content = '';
      this.selectedItem.attachmentFileName = '';
      this.file = '';
      this.closeModal();
    },
    onFileChanged(event) {
      const file = event.target.files[0];
      this.selectedItem.imageFileExtension = file.name.split('.').pop();
      this.toBase64(file).then((file1) => {
        this.selectedItem.imageBase64Content = file1.split(',').pop();
      });
      this.url = URL.createObjectURL(file);
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      const fileSize = this.file.size / 1024 / 1024;
      this.selectedItem.attachmentFileExtension = this.file.name
        .split('.')
        .pop();
      this.toBase64(this.file).then((file1) => {
        this.selectedItem.attachmentBase64Content = file1.split(',').pop();
      });
      this.selectedItem.attachmentFileName = URL.createObjectURL(this.file);
      if (!this.isValidType(this.file)) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.doneAlert({
          text: this.$t(
            'Only supports upload .pdf, .jpg, .jpeg, .bmp, .png, .doc, .docx, .zip suffix files'
          ),
          type: 'error',
        });
        this.file = '';
        return false;
      }
      if (this.isValidType(this.file) && fileSize > 5) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.attachmentFileName = '';
        this.doneAlert({
          text: this.$t('fileSizeExceedsFiveMiB'),
          type: 'error',
        });
        this.file = '';
        return false;
      }
    },
    isValidType(file) {
      return /\.(pdf|jpg|jpeg|bmp|png|doc|docx|zip)$/.test(file.name);
    },
    itemsSearchProvider() {
      this.get({ url: 'services' }).then((data) => {
        if (this.id) {
          this.itemsData = data.filter(
            (val) => val.stopped === false || this.selectedItem.invoiceLines.indexOf((x) => x.serviceId === val.id) === -1
            );
        } else {
          this.itemsData = data.filter((val) => val.stopped === false);
        }
      });
    },
    downloadItem(item) {
      const path = item.attachmentUrl.split('.')[1];
      saveAs(`${this.baseUrl}${item.attachmentUrl}`, `attached-file.${path}`);
    },
    closeModal() {
      this.$refs['upload-modal'].hide();
    },
    addExtrnalStudent() {
      var myWindow = window.open(
        '/#/students/new',
        '',
        `width=${window.screen.availWidth}height=${window.screen.availHeight}`
      );
      var popupTick = setInterval(function () {
        if (myWindow.closed) {
          clearInterval(popupTick);
          window.location.reload();
        }
      }, 500);
    },
    limit(event, limit) {
      if (this.selectedItem.notes.length >= limit) {
        event.preventDefault();
      }
    },
    hasClass() {
      if (this.fullscreen === true) {
        document.getElementById('virticalmenu').classList.add('display');
      } else {
        document.getElementById('virticalmenu').classList.remove('display');
      }
    },
    toggle() {
      this.fullscreen = !this.fullscreen;
    },
    toggleApi() {
      this.$fullscreen.toggle(this.$el.querySelector('.fullscreen-wrapper'), {
        teleport: this.teleport,
        callback: (isFullscreen) => {
          this.fullscreen = isFullscreen;
        },
      });
    },
    togglePendingItem() {
      if (
        this.selectedItem.invoiceLines && this.selectedItem.invoiceLines.length > 0
      ) {
        const pendingItem = JSON.parse(JSON.stringify(this.selectedItem));
        this.pendingItems.push(pendingItem);
        this.doneAlert({
          title: this.$t('pendingSucess'),
          type: 'success',
        });
        this.getSelecteItemObj();
      } else {
        this.doneAlert({ text: this.$t('selectItems'), type: 'warning' });
      }
    },
    retriveInvoice(item) {
      this.pendingItems = this.pendingItems.filter((v) => v !== item);
      this.selectedItem = item;
      this.$refs['invoices-modal'].hide();
    },
    changeStudent(v) {
      this.students = v;
      if (!this.isLineBaseDiscount) {
        this.selectedItem.discountPercentage = v.applyDiscount && !this.isLineBaseDiscount ? v.discount : 0;
      }
      this.selectedItem.discountPercentage = this.students.discount;
    },
    getSummary() {
      var totalPrice = 0;
      var totalDiscount = 0;
      this.selectedItem.discountValue = 0;
      this.selectedItem.discountPercentage = 0;
      this.selectedItem.invoiceLines.forEach((item) => {
        this.selectedItem.discountValue += parseFloat(item.discountValue) || 0;
        totalPrice += item.total;
        totalDiscount += parseFloat(item.discountValue) || 0;
      });
      this.selectedItem.discountPercentage = ((totalDiscount / totalPrice) * 100).toFixed(2);
    },
    computeTotal({ item, discountValue, discountPercentage }) {
      item.total = Number(item.price);
      item.net = Number(item.total);
      item.discountValue = Number(discountValue);
      if (discountPercentage > 0) {
        item.discountValue = (item.total * (discountPercentage / 100)).toFixed(
          2
        );
        item.net -= (item.total * discountPercentage) / 100;
      } else if (discountValue >= 0) {
        item.discountPercentage = ((discountValue / item.total) * 100).toFixed(
          2
        );
        item.discountValue = discountValue;
        item.net -= discountValue || 0;
        if (this.selectedItem.discountValue <= 0) {
          this.selectedItem.discountValue = 0;
          this.selectedItem.discountValue = discountValue || 0;
        } else {
          this.selectedItem.discountValue;
        }
      } else {
        item.net -= item.discountValue || 0;
      }
      this.getSummary();
    },
    computeTaxAndPriceBeforeTax(item) {
      this.hasService = true;
      if (item.discountValue > 0) {
        item.priceBeforeTax = (item.price - item.discountValue).toFixed(2);
      } else {
        item.priceBeforeTax = item.price;
      }
    },
    computeTotals() {
      this.selectedItem.netBeforeTaxes = 0;
      this.selectedItem.invoiceLines.forEach((invoice) => {
        this.selectedItem.netBeforeTaxes += (
            parseFloat(invoice.priceBeforeTax) - parseFloat(this.selectedItem.discountValue)
          ).toFixed(2);
      });

      // get discounted
      this.computeTotalDiscount();
      this.selectedItem.totalTaxes = 0;
      this.selectedItem.netBeforeTaxes = 0;
      this.selectedItem.total = 0;
      this.selectedItem.net = this.selectedItem.netBeforeTaxes;
      this.computeTotalTaxes();
    },
    computeDiscount({ value, Percentage }) {
      if (!this.selectedItem.total) return;
      if (this.isCalculatingDiscount) return;
      this.isCalculatingDiscount = true;
      value = value || 0;
      Percentage = Percentage || 0;
      if (value) {
        this.selectedItem.discountPercentage = this.fraction(
          (value / parseFloat(this.selectedItem.total)) * 100
        );
      } else {
        this.selectedItem.discountValue = this.fraction(
          (parseFloat(this.selectedItem.total) * Percentage) / 100
        );
      }
      this.isCalculatingDiscount = false;
    },
    save(typeInvoice) {
      if (!this.validateYear(this.selectedItem.transactionDate)) return;
      // check
      if (!this.selectedItem.studentId) return;
      if (!this.validateServices()) return;
      if (this.errors.length > 0) {
        this.doneAlert(this.errors[0]);
        return;
      }

      // maintain details
      this.invoiceData.items.forEach((element) => {
        if (!element.serviceId) {
          this.removeItem(element.lineSerial - 1, element);
        }
        this.selectedItem.invoiceLines = this.invoiceData.items;
      });

      // calling insert api
      this.isRequested = true;
      const url = 'DeservedFees';
      (this.id > 0
        ? this.update({ url, id: this.id, data: this.getModel() }).then(() => {
            if (typeInvoice === 'saveAndPrint') {
              this.print(this.id);
            }
            this.backToList();
          })
        : this.create({ url, data: this.getModel() })
      )
        .then((dataId) => {
          // if edit or from another source go to new after save
          if (typeInvoice === 'saveAndPrint') {
            this.print(dataId);
          }
          this.doneAlert({
            title: this.$t('savedSuccessfully'),
            type: 'success',
          });
          this.hasService = false;
          this.$refs.autoComlete.clearSearch();
          this.backToList();
          this.isRequested = false;
          this.getSelecteItemObj();
        })
    },
    backToList() {
      this.$router.push({ name: 'deservedFees' });
    },
    print(id) {
      const printedItem = {
        id: id,
      };
      this.printReport(this.isRight ? 'DeservedFee-ar' : 'DeservedFee-en', printedItem);
    },
    clearData() {
      if (this.id) {
        this.$router.push({ name: 'deservedFees-new' });
      }
      this.selectedItem.studentId = null;
      this.getSelecteItemObj();
      this.invoiceData.items.forEach((element) => {
        this.trTrimHeight(this.$refs.row[element.lineSerial - 1].offsetHeight);
      });
      this.invoiceData.items = [];
      this.addNewItemInItemForm();
      this.file = '';
      this.hasService = false;
      this.selectedItem.studentNameAr = '';
    },
    addNewItemInItemForm() {
      this.$refs.form.style.overflow = 'hidden';
      this.invoiceData.items.push({
        itemArabicName: '',
        itemEnglishName: '',
        serviceId: '',
        price: 0,
        total: 0,
        net: 0,
        notes: '',
        priceBeforeTax: 0,
        discountValue: 0,
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
        setTimeout(() => {
          this.$refs.form.style.overflow = null;
        }, 350);
      });
    },
    removeItem(iex, item) {
      this.invoiceData.items.splice(iex, 1);
      this.selectedItem.discountValue -= !item.discountValue ? 0 : item.discountValue;
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
      if (this.invoiceData.items.length <= 0) {
        this.selectedItem.invoiceLines = [];
        this.hasService = false;
        this.selectedItem.discountValue = 0;
        this.selectedItem.discountPercentage = 0;
      }
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
    validateServices() {
      for (let index = 0; index < this.selectedItem.invoiceLines.length; index++) {
        const element = this.selectedItem.invoiceLines[index];
        if (element.net < 0 || parseFloat(element.price) <= 0) {
          this.doneAlert({ text: this.$t('serivcePriceMustBeMoreThanZeroInRowNo', { rowNo: element.lineSerial }), type: 'warning', timer: 4000 });
          return false;
        }
      }
      return true
    },
    Help(salesId) {
      this.$router.push({
        name: 'pos-invoice-edit',
        params: { id: salesId },
      });
    },
    convertToSaleInvoice() {
      if (!this.validateServices()) return;
      if (!this.validateLines()) return;

      this.create({ url: 'DeservedFees/ConvertToSales', data: this.getModel() }).then(() => {
        this.doneAlert({ text: this.$t('convertedToSaleInvoiceSucessfully') });
        this.backToList();
      });
    },
    validateLines() {
      const existsInvalidValues = this.originalFees.find((x) => x.isValid === false)
      if (existsInvalidValues) {
        this.doneAlert({ text: this.$t('serivceDateMustBeLessThanOrEqualInRowNo', { price: existsInvalidValues.price, discount: existsInvalidValues.discount, rowNo: existsInvalidValues.lineNo }), type: 'warning', timer: 4000 });
        return false;
      }
      return true;
    },

    priceChanged(changedService) {
      if (!this.isConvertToSale) return;
      if (!this.validateServices()) return;
      const realService = this.originalFees.find((x) => x.seriveId === changedService.id);
      realService.isValid = parseFloat(changedService.price) <= realService.price;
    },

    discountChanged(changedService) {
      if (!this.isConvertToSale) return;
      const realService = this.originalFees.find((x) => x.seriveId === changedService.id);
      const val = changedService.discountValue === '' ? 0 : parseInt(changedService.discountValue)
      realService.isValid = val <= realService.discount && parseFloat(changedService.price) <= realService.price;
    },

    getFeesInvoices() {
      if (this.id > 0) {
        this.get({ url: `DeservedFees/GetFeesInvoices?feeId=${this.id}` }).then((data) => {
          this.feesInvoices = data;
        })
      }
    }
  },
};
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/vue/libs/vue-flatpicker.scss';

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}

@media (max-width: 768px) {
  .sticky-content-top span {
    margin-bottom: 10px;
  }
}

.service-title {
  width: min-content;
  background-color: #918fdf;
  border: 1px solid #fff;
  color: #fff;
  padding: 8px;
  text-align: center;
  border-radius: 4px;
}

.repeater-form .service-feature {
  padding-left: 3px;
  padding-right: 3px;
}

.repeater-form .service-counter {
  width: 20px;
  border: 1px solid;
  border-radius: 40%;
  text-align: center;
  background-color: antiquewhite;
  margin: 0 auto;
}
</style>
